import { FunctionComponent, ReactElement } from 'react'
import cx from 'classnames'
import styles from './findACar.module.scss'
import Link, { linkDefaultProps } from '../common/link'

/**
 * Research a car, Buy a car and Go buttons in a row,
 * with make-model-search components in between for mobile layout.
 * @type {Specific Component}
 * @param isMobile whether is using mobile layout
 * @param isSSR whether is for serverside rendering
 * @param setSelectedBtn
 * @param selectedBtn determines either showroom or listing make model search should be shown
 * @param radioIds available id for radio inputs, for SEO support
 * @param showroomSearchFragment  SEO supported ReactElement for showroom make model search
 * @param cfsSearchFragment SEO supported ReactElement for listing make model search
 */
const FindACarBtnRow: FunctionComponent<Props> = ({
  isMobile,
  isSSR,
  setSelectedBtn,
  selectedBtn,
  radioIds,
  showroomSearchFragment,
  cfsSearchFragment
}) => {
  return (
    <>
      <div className={cx(styles['d-find-a-car__btn-row'])}>
        <button
          className={cx(styles['d-find-a-car__btn'], {
            [styles['d-find-a-car__btn--selected']]: selectedBtn == radioIds.research && selectedBtn
          })}
          onClick={() => {
            setSelectedBtn(selectedBtn === radioIds.research ? '' : radioIds.research)
          }}
        >
          <label
            className={cx(styles['d-find-a-car__label'], {
              [styles['d-find-a-car__label--notselect']]:
                selectedBtn !== radioIds.research && !!selectedBtn
            })}
            htmlFor={radioIds.research}
          >
            Research
          </label>
        </button>

        <button
          className={cx(styles['d-find-a-car__btn'], {
            [styles['d-find-a-car__btn--selected']]: selectedBtn == radioIds.buy && !!selectedBtn
          })}
          onClick={() => {
            setSelectedBtn(selectedBtn === radioIds.buy ? '' : radioIds.buy)
          }}
        >
          <label
            className={cx(styles['d-find-a-car__label'], {
              [styles['d-find-a-car__label--notselect']]:
                selectedBtn !== radioIds.buy && !!selectedBtn
            })}
            htmlFor={radioIds.buy}
          >
            Buy{' '}
          </label>
        </button>

        <Link {...linkDefaultProps} href={'/sell-my-car/'}>
          <a className={cx(styles['d-find-a-car__btn'], styles['d-find-a-car__btn--link'])}>
            <span
              className={cx(styles['d-find-a-car__label'], {
                [styles['d-find-a-car__label--notselect']]: !!selectedBtn
              })}
            >
              Sell{' '}
            </span>
          </a>
        </Link>
      </div>
      {/*mobile layout, always render to support ssr, allow to destroy to clear states when it is browser  */}
      {isMobile && (isSSR || selectedBtn === radioIds.research) ? showroomSearchFragment : null}
      {/*mobile layout, always render to support ssr, allow to destroy to clear states when it is browser  */}
      {isMobile && (isSSR || selectedBtn === radioIds.buy) ? cfsSearchFragment : null}
    </>
  )
}

type Props = {
  isMobile: boolean
  isSSR: boolean
  setSelectedBtn: (btn: string) => void
  selectedBtn: string
  radioIds: Record<string, string>
  showroomSearchFragment: ReactElement
  cfsSearchFragment: ReactElement
}

export default FindACarBtnRow
