import cx from 'classnames'
import styles from './car-thumbnail.module.scss'
import Link from '../../common/link'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import ThumbnailImage from '../../common/thumbnail-image'
import { priceFormat } from '../../../utils/functions/miscellaneous'
import { LocationIcon as PinIcon } from '../../icons'
import DisclaimerTooltip from '@grille/components/common/disclaimer-tooltip'
import { Color } from '@grille/constants/colors'

const CarThumbnail = ({
  car,
  compact,
  smallWidth,
  isCarsForSaleDefaultImage = false,
  imageLoading = 'lazy',
  imageAttributes = {}
}) => {
  if (isEmpty(car)) {
    return null
  }

  const price = priceFormat(car?.price)
  const priceType = car?.isEgcAllowed ? 'EGC' : 'DAP'
  const disclaimerType = car?.isEgcAllowed ? 'EXCL_GOVT_CHARGES' : 'DRIVE_AWAY_PRICE'
  const image = {
    loading: imageLoading,
    sourceUrl: car?.sourceUrl ?? '',
    className: cx(styles['drive-cfs__cars-thumbnail__img']),
    cloudinary: car?.cloudinary,
    ...imageAttributes
  }
  return (
    <div className={cx(styles['drive-cfs__cars-thumbnail__wrapper'])}>
      <Link href={`${car?.uri}`}>
        <a
          className={cx(styles['drive-cfs__cars-thumbnail__link-wrapper'], {
            [cx(styles['drive-cfs__cars-thumbnail__link-compact'])]: !compact,
            [cx(styles['drive-cfs__cars-thumbnail__link-desktop'])]: !compact && !smallWidth
          })}
        >
          <figure className={cx(styles['drive-cfs__cars-thumbnail__figure'])}>
            {/* @TODO: Update image to Default Image */}
            <ThumbnailImage
              image={image}
              cloudinary={car?.cloudinary}
              postTitle={car?.altText}
              sizeName='CARS_FOR_SALE_SECTION'
              isCarsForSaleDefaultImage={isCarsForSaleDefaultImage}
              altText={car?.nicename}
            />
            {car?.type && (
              <div className={cx('category', styles['drive-cfs__cars-thumbnail__type-wrapper'])}>
                <span className={cx(styles['drive-cfs__cars-thumbnail__type'])}>
                  Dealer {car?.type}
                </span>
              </div>
            )}
          </figure>
        </a>
      </Link>
      <div
        className={cx(styles['drive-cfs__cars-thumbnail__car-name'], {
          [cx(styles['drive-cfs__cars-thumbnail__car-name-compact'])]: compact,
          [cx(styles['drive-cfs__cars-thumbnail__car-name-full'])]: !compact
        })}
      >
        <Link href={`${car?.uri}`}>
          <a
            className={cx(styles['drive-cfs__cars-thumbnail__car-name-link'], 'truncate-2', {
              [cx(styles['drive-cfs__cars-thumbnail__car-name-link-compact'])]: compact,
              [cx(styles['drive-cfs__cars-thumbnail__car-name-link-desktop'])]:
                !compact && !smallWidth
            })}
          >
            {car?.nicename}
          </a>
        </Link>
        <div className={cx(styles['drive-cfs__cars-thumbnail__price-wrapper'])}>
          <p className={cx(styles['drive-cfs__cars-thumbnail__price'])}>$ {price}</p>
          <p className={cx(styles['drive-cfs__cars-thumbnail__price-type'])}>{priceType}</p>
          <DisclaimerTooltip
            disclaimerType={disclaimerType}
            iconProps={{
              color: Color.gray500
            }}
          />
        </div>
        {!compact && car?.location ? (
          <div className={cx(styles['drive-cfs__cars-thumbnail__location-wrapper'])}>
            <PinIcon
              height='24'
              className={cx(styles['drive-cfs__cars-thumbnail__location-icon'])}
            />
            <p className={cx(styles['drive-cfs__cars-thumbnail__location'])}>{car.location}</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

CarThumbnail.propTypes = {
  compact: PropTypes.bool,
  car: PropTypes.object.isRequired,
  isCarsForSaleDefaultImage: PropTypes.bool,
  smallWidth: PropTypes.bool,
  imageLoading: PropTypes.oneOf(['lazy', 'eager'])
}

export default CarThumbnail
