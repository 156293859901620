import cx from 'classnames'
import styles from './make-model-search.module.scss'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import Link, { linkDefaultProps } from '../../common/link'
import Dropdown from './dropdown'
import { ListingModelsData, Make, Option, ShowroomModelsData } from '../../../types/findACar'
import { GroupBase, OptionsOrGroups } from 'react-select'
import Button from '../../common/button'
import { getLink, getMakeOptions } from './functions'
import { GET_LISTING_MODELS_AVAILABLE } from '../../../queries/cars-for-sale/listings/get-cars-for-sale-models'
import { GET_TP_COMPARE_SPECS_MAKE_MODELS } from '../../../queries/compare-cars/get-dropdowns'
import { useQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import logger from '../../../utils/logger'
import orderBy from 'lodash/orderBy'

/**
 * make model search component which involves search dropdowns for make and model, and a go button with link generated based on selected values.
 * supports only showroom or listing search
 * @type {Specific Component}
 * @param makes object container all makes and popular makes. popular makes is only used for showroom make model search
 * @param searchType determines whether this search is for showroom or car for sale listings
 * @param customClass @optional custom class name applies to wrapper
 * @param idPrefix used as id prefix for components for tracking instance. eg. Select
 */
const MakeModelSearch: FunctionComponent<Props> = ({
  makes,
  searchType,
  customClass,
  isSSR,
  idPrefix
}) => {
  const [modelOptions, setModelOptions] = useState<Option[]>([])
  const [selectedOptions, setSelectedOptions] = useState<{
    make: Option | null
    model: Option | null
  }>({ make: null, model: null })
  const { refetch: refetchListingModels } = useQuery<ListingModelsData>(
    GET_LISTING_MODELS_AVAILABLE,
    { context: { listing: true }, variables: { where: {} }, skip: true }
  )
  const { refetch: refetchShowroomModels } = useQuery<ShowroomModelsData>(
    GET_TP_COMPARE_SPECS_MAKE_MODELS,
    { context: { tailpipe: true }, variables: { makeId: '' }, skip: true }
  )

  const changeListingModels = useCallback(
    async (makeSlug: string) => {
      const { data, errors } = await refetchListingModels({
        where: { makeSlug: { eq: makeSlug } }
      })

      if (!isEmpty(data) && isEmpty(errors)) {
        const newModels = data?.availableModels?.options.map(({ name, slug }) => ({
          label: name,
          slug,
          value: slug
        }))

        const sortedModels: Option[] = orderBy(
          newModels,
          [(option) => option?.label.toLowerCase()],
          ['asc']
        )
        setModelOptions(sortedModels)
      } else {
        logger.error('Error fetching listng models', errors)
      }
    },
    [refetchListingModels]
  )
  const changeShowroomModels = useCallback(
    async (makeUuid: string) => {
      const { data, errors } = await refetchShowroomModels({ makeId: makeUuid })
      if (!isEmpty(data) && isEmpty(errors)) {
        const newModels = data?.models?.results?.[0]?.families ?? []
        const sortedModels: Option[] = orderBy(
          newModels,
          [(option) => option?.label.toLowerCase()],
          ['asc']
        )
        setModelOptions(sortedModels)
      } else {
        logger.error('Error fetching showroom models', errors)
      }
    },
    [refetchShowroomModels]
  )

  const changeModel: (selectedMakeValue: string) => Promise<void> =
    searchType === 'car-for-sale' ? changeListingModels : changeShowroomModels

  useEffect(() => {
    if (selectedOptions.make?.value) {
      changeModel(selectedOptions.make?.value)
    }
  }, [changeModel, selectedOptions.make?.value])

  //set make and new go link when make is selected
  const handleSelectMake = (newMake: Option | null) => {
    if (selectedOptions.make !== newMake) {
      setSelectedOptions({ make: newMake, model: null })
    }
  }

  const handleSelectModel = (newModel: Option | null) => {
    if (newModel !== selectedOptions.model) {
      setSelectedOptions({ ...selectedOptions, model: newModel })
    }
  }

  const popularMakes = getMakeOptions(makes?.popularMakes)
  const allMakes = getMakeOptions(makes?.allMakes)
  const makeOptions: OptionsOrGroups<any, GroupBase<any>> = [
    {
      label: 'Popular Makes',
      options: orderBy(popularMakes, [(option) => option?.label.toLowerCase()], ['asc'])
    },
    {
      label: 'All Makes',
      options: orderBy(allMakes, [(option) => option?.label.toLowerCase()], ['asc'])
    }
  ]

  const goLink: string = getLink(searchType, selectedOptions.make, selectedOptions.model)
  return (
    <div className={cx(styles['d-make-model-search__wrapper'], customClass)}>
      <div className={styles['d-make-model-search__container']}>
        <Dropdown
          placeHolder='Make'
          options={makeOptions}
          selectedOption={selectedOptions.make}
          onSelect={handleSelectMake}
          isDisabled={false}
          searchMake={null}
          getOptionLink={(make: Option | null, model: Option | null) =>
            getLink(searchType, make, model)
          }
          isSSR={isSSR}
          idPrefix={`${idPrefix}-make`}
          id={`${idPrefix}-make-dropdown`}
        />
        <Dropdown
          placeHolder='Model'
          options={modelOptions}
          selectedOption={selectedOptions.model}
          onSelect={handleSelectModel}
          isDisabled={selectedOptions.make === null}
          searchMake={selectedOptions.make}
          getOptionLink={(make: Option | null, model: Option | null) =>
            getLink(searchType, make, model)
          }
          idPrefix={`${idPrefix}-model`}
          id={`${idPrefix}-model-dropdown`}
        />
        <div className={styles['d-make-model-search__btn-wrapper']}>
          <Link {...linkDefaultProps} href={goLink}>
            <a>
              <Button
                disabled={!selectedOptions.make}
                color=''
                type='button'
                className={cx(styles['d-make-model-search__btn'])}
              >
                Search
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

type Props = {
  makes: {
    allMakes: Make[]
    popularMakes: Make[]
  }
  searchType: 'showroom' | 'car-for-sale'
  customClass?: string
  isSSR?: boolean
  idPrefix: string
}

export default MakeModelSearch
