import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Color } from '../../../constants/colors'

/**
 * Custom styles used for React Select
 */
const customStylesReactSelect = (
  selected: string | number | null,
  colors: {
    hoverControl: Color | string
    hoverDropdownIndicator: Color | string
    optionBackground: Color | string
    optionFocusedBackground: Color | string
    option: Color | string
    optionSelected: Color | string
  }
): StylesConfig<any, false, GroupBase<any>> | undefined => {
  return {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      '&:hover': {
        borderColor: colors.hoverControl
      }
    }),

    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      ':hover': {
        borderColor: colors.hoverDropdownIndicator
      }
    }),

    option: (provided: CSSObjectWithLabel, state: OptionProps<any, false, GroupBase<any>>) => {
      // state.isSelected only works for multiSelect
      return {
        ...provided,
        backgroundColor: state?.isFocused
          ? colors.optionFocusedBackground
          : colors.optionBackground,
        color:
          state?.data?.value === selected ? `${colors.optionSelected} !important` : colors.option
      }
    },
    valueContainer: (provided) => ({
      ...provided,
      overflow: 'visible'
    }),
    placeholder: (base) => {
      return {
        ...base,
        position: 'absolute'
      }
    }
  }
}

export default customStylesReactSelect
